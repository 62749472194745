#mindMapContainer {
  width: 100vw;
  height: 100vh;
}

#mindMapContainer * {
  margin: 0;
  padding: 0;
}
.eye-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("../images/file-eye.png"); /* 确保路径正确 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  margin-left: 15px; /* 设置图标与主内容的间距 */
  cursor: pointer; /* 设置鼠标悬停时的指针样式 */
}

.play-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("../images/playCircle.png"); /* 确保路径正确 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  margin-left: 15px; /* 设置图标与主内容的间距 */
  cursor: pointer; /* 设置鼠标悬停时的指针样式 */
}

.img-show-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("../images/img-show-icon.png"); /* 确保路径正确 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  margin-left: 15px; /* 设置图标与主内容的间距 */
  cursor: pointer; /* 设置鼠标悬停时的指针样式 */
}
